import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AuthService, Roles, UserService } from '@SiteOwl/core';

export const accountResolver: ResolveFn<boolean> = (route, state) => {
  if (inject(AuthService).getUserKeyData() && Object.keys(inject(AuthService).getUserKeyData()).length > 0) {
    if (inject(AuthService).getUserKeyData().userRole === Roles.Administrator) {
      return inject(UserService).getActiveCustomers();
    } else {
      return inject(UserService).getActiveCustomersByUser()
    }
  }
};
