import { AuthService, CommonService, Environment, MessageService, MixpanelObjectService, Roles, UserService, Utilities } from '@SiteOwl/core';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FronteggAuthService } from '@frontegg/angular';

@Component({
  selector: 'so-frontegg-success',
  templateUrl: './frontegg-success.component.html',
  styleUrls: ['./frontegg-success.component.scss'],
})
export class FronteggSuccessComponent implements OnInit {
  returnUrl!: string;
  dashboardTabSelection!: string;
  constructor(
    private authService: AuthService,
    private mixpanelService: MixpanelObjectService,
    private userService: UserService,
    private router: Router,
    private commonService: CommonService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private readonly env: Environment,
    private inject: Injector) { }
  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || localStorage.getItem('returnUrl');
    if (this.env.fronteggLogin) {
      this.inject.get(FronteggAuthService).user$.subscribe((user: any) => {
        if (user) {
          localStorage.removeItem('fromFloorPlan')
          if (this.authService.isUserLoggedIn() && user.email !== this.authService.getDecodedJWTToken().email) {
            this.messageService.warningMessage('User: ', 'Active user session terminated.')
          }
          if (this.authService.isUserLoggedIn() && user.email === this.authService.getDecodedJWTToken().email) {
            this.authService.saveUserToken(user.accessToken)
          } else {
            this.commonService.clearLocalStorage();
            if (this.authService.getCustomerId() && this.authService.getDecodedFromRes(user.accessToken).email !== this.authService.getCustomerId().user) {
              this.authService.setCustomerId({ customerId: 0, user: '' });
            }
            this.commonService.setLocalStorageString('loginType', 'frontegg');
            this.authService.saveUserToken(user.accessToken).then(() => {
              this.commonService.removeItem('LocalStorageKeys.USER')
              this.userService.updateUserMFA(encodeURIComponent(user.email)).subscribe((r: any) => {
                const timeOut = setTimeout(() => {

                  this.userService.getUserDetails().subscribe({
                    next: (res: any) => {
                      this.authService.saveUserData(res);
                      this.authService.setCustomerId({ customerId: this.authService.getCustomerId().customerId, user: res.user.email });
                      this.mixpanelService.trackEvent('Login', {});
                      if (!Utilities.isEmpty(this.returnUrl)) {
                        if (res.user.profileCompleted) {
                          this.router.navigateByUrl(this.returnUrl);
                        } else {
                          this.router.navigate(['/public/account-setup'], { state: { profile: res.user }, relativeTo: this.route });
                        }
                      } else if (res.user.role.name === Roles.User && res.customerRoleName === Roles.Technician) {
                        if (res.user.profileCompleted) {
                          this.router.navigate(['/secure/dashboard/customer'])
                        } else {
                          this.router.navigate(['/public/account-setup', { state: { profile: res.user } }]);
                        }
                      } else {
                        if (res.user.profileCompleted) {
                          this.dashboardTabSelection = 'design'
                          if (res.user.dashboardTab) {
                            this.dashboardTabSelection = res.user.dashboardTab;
                          }
                          this.router.navigate(['/secure/dashboard/customer'], { relativeTo: this.route })
                        } else {
                          this.router.navigate(['/public/account-setup'], { state: { profile: res.user }, relativeTo: this.route });
                        }
                      }
                    }, error: (err: any) => {
                      if (err.status === 400) {
                        if (document.getElementsByTagName('frontegg-app').length > 0) {
                          document.getElementsByTagName('frontegg-app')[0].remove();
                        }
                        this.router.navigate(["secure", "error", err.status])
                      } else {
                        this.authService.clearUserData();
                        this.router.navigate(['public/login']);
                      }
                    }
                  });
                  localStorage.removeItem('returnUrl');
                  clearTimeout(timeOut)
                }, 500);
              })
            })
          }
        } else {
          this.inject.get(FronteggAuthService).loginWithRedirect();
        }
      })
    }
  }
}
