/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FronteggAuthService } from '@frontegg/angular';
import { AuthService, EventService, MessageService, MixpanelObjectService, EventEmitterType, CommonService, LocalStorageKeys, Environment, LoaderService, FloorPlanLoadService } from '@SiteOwl/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SyncPopupComponent } from '@SiteOwl/floor-plan';
import { NecessaryPopupComponent } from '@SiteOwl/UI';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
  selector: 'so-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss'],
})
export class SecureComponent implements OnInit, AfterViewInit {
  bsModalRef?: BsModalRef;
  isAlreadyChecked = false;
  isLoadingDone = false;
  init = true;
  constructor(private messageService: MessageService, private mixpanelService: MixpanelObjectService, private modalService: BsModalService, private router: Router, private dbService: NgxIndexedDBService, private authService: AuthService, private eventService: EventService, private commonService: CommonService, private readonly env: Environment, private injector: Injector, public loaderService: LoaderService, public floorLoadService: FloorPlanLoadService) {

  }
  ngOnInit(): void {
    if (this.env.fronteggLogin) {
      this.injector.get(FronteggAuthService).user$.subscribe((user: any) => {
        if (user) {
          let currentUrl: any = this.router.url;
          const hasToken = !this.commonService.getLocalStorageString(LocalStorageKeys.TOKEN)
          this.authService.saveUserToken(user.accessToken)
          if (hasToken && !localStorage.getItem('fromFloorPlan')) {
            setTimeout(() => {
              this.router.navigate([currentUrl], { onSameUrlNavigation: 'reload' });
            }, 0);
          }
          if (localStorage.getItem('fromFloorPlan') && localStorage.getItem('fromFloorPlan')) {
            currentUrl = localStorage.getItem('fromFloorPlan');
            this.router.navigate([currentUrl], { onSameUrlNavigation: 'reload' });
            localStorage.removeItem('fromFloorPlan')
          }
        }
      });
      if (this.authService.getUserKeyData() && this.authService.getUserKeyData() && this.authService.getUserKeyData().isLocalSaveEnable && localStorage.getItem('isSaveChangeRemains') === 'true') {
        this.injector.get(FronteggAuthService).isLoading$.subscribe(res => {
          if (this.init) {
            if (!res && this.isLoadingDone !== res) {
              this.init = false;
              this.getDetailsOfIndexDB();
            }
          }
          this.isLoadingDone = res;
        }, err => {
          this.getDetailsOfIndexDB();
          this.init = false;
          this.isLoadingDone = false;
        })
      } else {
        this.getDetailsOfIndexDB();
      }
    } else {
      this.getDetailsOfIndexDB();
    }
  }
  ngAfterViewInit(): void {
    this.isAlreadyChecked = false;
  }

  checkValueInIndexDB(details: any) {
    const eventData = {
      'Account ID': details[0].data.customerId,
      'Account Name': details[0].data.customerName,
      'Area': details[0].data.isProject ? 'Project' : 'Live',
      'Location': details[0].data.name,
      'Plan': details[0].data.floorName
    };
    this.mixpanelService.trackEvent('Unsaved Changes Prompt', eventData)
    const initialState: ModalOptions = {
      initialState: { details: details[0].data, btnSuccessText: 'Go To Plan', header: 'Unsaved Changes', }, class: '', ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(NecessaryPopupComponent, initialState);
    this.bsModalRef.content.eventForFloorPlan.subscribe(() => {
      this.bsModalRef?.hide();
      if (details[0].data.isProject) {
        this.router.navigate([`/secure/project/${details[0].data.projectId}/building/${details[0].data.buildingId}/floor/${details[0].data.floorId}/details`], {
          queryParams: { unsaved: true }
        });
      } else {
        this.router.navigate([`/secure/site/${details[0].data.siteId}/building/${details[0].data.buildingId}/floor/${details[0].data.floorId}/details`], {
          queryParams: { unsaved: true }
        });
      }
    })
  }

  getDetailsOfIndexDB() {
    if (this.authService.getUserKeyData().isLocalSaveEnable && localStorage.getItem('isSaveChangeRemains') === 'true') {
      let isDataRemain = false
      this.dbService.getAll("favDevices").subscribe((r: any) => {
        if (r && r.length > 0) {
          isDataRemain = true;
        }
      }, (err: any) => {
        if (err.includes('objectStore does not exists')) {
          this.dbService.createObjectStore({
            store: 'favDevices',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
          })
        }
      })
      this.dbService.getAll("floorDevices").subscribe((r: any) => {
        if (r && r.length > 0) {
          isDataRemain = true;
        }
      }, (err: any) => {
        if (err.includes('objectStore does not exists')) {
          this.dbService.createObjectStore({
            store: 'floorDevices',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
          })
        }
      })
      this.dbService.getAll("commonDetails").subscribe((details: any) => {
        if (details && details !== undefined && details.length > 0) {
          this.dbService.getAll("syncDetails").subscribe((syncData: any) => {
            if (syncData && syncData.length > 0 && syncData[0].syncId && syncData[0].syncId !== undefined) {
              const isProject = details[0].data.projectId > 0 ? true : false;
              const id = details[0].data.projectId > 0 ? details[0].data.projectId : details[0].data.siteId;
              this.authService.checkSyncStauts(isProject, id, details[0].data.floorId, syncData[0].syncId, syncData[0].folderName).subscribe((e: any) => {
                if (e.body.code === 'PROCESSING') {
                  if (!this.isAlreadyChecked) {
                    this.isAlreadyChecked = true;
                    const data: any = {
                      deviceCnt: syncData[0].deviceCnt,
                      taskCnt: 0,
                      imageCnt: syncData[0].imgCnt,
                      imgUploadedCnt: syncData[0].imgCnt,
                      totalDiscardCount: syncData[0].deviceCnt,
                      endSyncCallStarted: true,
                      stopEndApiCall: false
                    }
                    const initialState: ModalOptions = {
                      initialState: data, class: '', ignoreBackdropClick: true
                    };
                    this.bsModalRef = this.modalService.show(SyncPopupComponent, initialState);
                  }
                  const timeOut = setTimeout(() => {
                    this.getDetailsOfIndexDB();
                    clearTimeout(timeOut);
                  }, 3000);
                } else {
                  this.bsModalRef?.hide();
                  this.checkSyncStatus(e, details); // if user has click on save change button, but sync end call is not triggered.
                }
              })
            } else {
              this.isAlreadyChecked = false;
              this.checkValueInIndexDB(details); // if user has not click on save change button.
            }
          }, () => {
            this.dbService.clear('syncDetails').subscribe(() => {
              this.isAlreadyChecked = false;
              this.checkValueInIndexDB(details); // if user has not click on save change button.
            })
          })
        }
      }, (e) => {
        if (e.includes('The requested version')) {
          this.getDetailsOfIndexDB();
        } else if (e.includes('objectStore does not exists')) {
          this.dbService.createObjectStore({
            store: 'commonDetails',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
          })
        }
      })
    }
  }

  checkSyncStatus(e: any, details: any) {
    if (e.body.code === 'SUCCEEDED') {
      this.messageService.successMessage('Plan Details: ', 'Saved successfully.')
      this.dbService.clear("favDevices").subscribe(() => {
        this.dbService.clear("floorDevices").subscribe(() => {
          this.dbService.clear("commonDetails").subscribe(() => {
            this.dbService.clear("syncDetails").subscribe(() => { })
            localStorage.setItem('isSaveChangeRemains', 'false');
          })
          const timeOut = setTimeout(() => {
            this.eventService.broadcast(EventEmitterType.endSynCallCompleted, {});
            clearTimeout(timeOut)
          }, 500);
        })
      })
    } else if (e.body.code === 'FAILED') {
      this.messageService.errorMessage('Transaction failed: ', 'Failed to sync changes.')
      this.checkValueInIndexDB(details);
    } else if (e.body.code === 'NOT_STARTED') {
      console.log(e.body, '=====')
      this.dbService.clear("syncDetails").subscribe(() => {
        this.checkValueInIndexDB(details);
      })
    }
    this.isAlreadyChecked = false;
  }
}
